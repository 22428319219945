import React  from 'react';
import './OpacitySwipe.css'
class OpacitySwipe extends React.Component {
	constructor(props) {
			super(props);
			this.state = {
				parentObj: props,
				isTouch: false,
				startCoord:[0,0],
				touchObj: [0,0],
				paginations:[],
				sildes:[],
				idx:[0,1,2,3,4,5,6]
			};
			this.prevBtn = this.prevBtn.bind(this);
			this.nextBtn = this.nextBtn.bind(this);	
			this.touchStart = this.touchStart.bind(this);
			this.touchEnd = this.touchEnd.bind(this);	
			this.touchMove = this.touchMove.bind(this);	
			this.pagList = this.pagList.bind(this);		
			this.swipeDom = this.swipeDom.bind(this);
			this.activeChg = this.activeChg.bind(this);
			this.idxSet = this.idxSet.bind(this);
			this.pagIdxSet = this.pagIdxSet.bind(this);
			this.idxCal = this.idxCal.bind(this);
		}
	/*인덱스 설정*/
	idxCal(idx,num){
		const btnNumber = Number(this.state.parentObj.btnNum)
		var index = (idx+num) % btnNumber
		if(index<0)
			index+=btnNumber
		return(index)
	}		
	/* paginations 인덱스 */
	idxSet(num){
		const idx = this.state.idx[3]
		var index = [this.idxCal(idx,num-3),this.idxCal(idx,num-2),this.idxCal(idx,num-1),idx,this.idxCal(idx,num+1),this.idxCal(idx,num+2),this.idxCal(idx,num+3)]
		index[3] = this.idxCal(idx,num)
		this.setState({idx: index}, () =>{this.activeChg()})
	}
	/* 이전 클릭 이벤트 */
	prevBtn(){
		this.idxSet(-1)
	}
	/* 다음 클릭 이벤트 */
	nextBtn(){
        this.idxSet(1)
	}

	/* 터치 시작 좌표 저장 */
	touchStart=(e)=>{
		this.setState({
			startCoord : [e.clientX,e.clientY],
			isTouch:true
		});
		e.preventDefault();
	}
	/* 터치 시작 좌표 초기화 */
	touchEnd=(e)=>{
		this.setState({
			touchObj : [0,0],
			isTouch:false
		});
	}
	/* 터치 움직임 좌표 저장 */
	touchMove=(e)=>{
		if(this.state.isTouch){
			this.setState({touchObj : [e.clientX,e.clientY]},()=>{
				const coordCng = this.state.startCoord[0]-this.state.touchObj[0]
				if(coordCng>100){
					this.prevBtn()
					this.touchEnd()
				}
				else if(coordCng<-100){
					this.nextBtn()
					this.touchEnd()
				}
				
			});
		}
	}
	/* 초기 스와이프 DOM */
	swipeDom = () =>{
		const leng = this.state.parentObj.children.length
		const initDom = [this.state.parentObj.children[leng-3]]
		initDom[1] = [this.state.parentObj.children[leng-2]]
		initDom[2] = [this.state.parentObj.children[leng-1]]
		for(var i=3;i<leng;i++){
			initDom[i] = this.state.parentObj.children[i-3]
		}		
		this.setState({sildes:initDom})
	}
	/* index에 따른 active 바꾸기 */
	activeChg = ()=>{
		// 내용 인덱스 교체
		const slides = document.querySelectorAll('.slide')
		if(document.querySelector('.active')){
			document.querySelector('.active').classList.remove('active')
			document.querySelector('.prev1').classList.remove('prev1')
			document.querySelector('.prev2').classList.remove('prev2')
			document.querySelector('.prev3').classList.remove('prev3')
			document.querySelector('.next1').classList.remove('next1')
			document.querySelector('.next2').classList.remove('next2')
			document.querySelector('.next3').classList.remove('next3')
		}
		slides[this.state.idx[0]].classList.add('prev3')
		slides[this.state.idx[1]].classList.add('prev2')
		slides[this.state.idx[2]].classList.add('prev1')
		slides[this.state.idx[3]].classList.add('active')
		slides[this.state.idx[4]].classList.add('next1')
		slides[this.state.idx[5]].classList.add('next2')
		slides[this.state.idx[6]].classList.add('next3')
		//pagnation 인덱스 교체
		if(document.querySelector('.pag-active')){
			document.querySelector('.pag-active').classList.remove('pag-active')
		}
		const pagnation = document.querySelectorAll('.pag-btn')
		pagnation[this.state.idx[0]].classList.add('pag-active')
	}
	/* paginations DOM */
	pagList = () =>{
		const pags = []
		this.state.parentObj.paginations.split(',').forEach((i,idx)=>{
			if(idx ===0){
				pags.push(<button className ="pag-btn pag-active" onClick={(e)=>{this.pagIdxSet(idx,e)}} key={i} >{i}</button>)
			}
			else{
				pags.push(<button className ="pag-btn" onClick={(e)=>{this.pagIdxSet(idx,e)}} key={i} >{i}</button>)
			}
		})
		this.setState({paginations : pags});
	}
	/*인덱스 설정*/
	pagIdxSet(idx,e){
		var index = [idx,this.idxCal(idx,1),this.idxCal(idx,2),this.idxCal(idx,3),this.idxCal(idx,4),this.idxCal(idx,5),this.idxCal(idx,6)]
		this.setState({idx: index}, () =>{this.activeChg()})
	}
	componentDidMount(){
		const swiperXscroll = document.querySelector('.swiper-xscroll')
		swiperXscroll.addEventListener('mousedown',this.touchStart)
		swiperXscroll.addEventListener('mousemove',this.touchMove)
		document.addEventListener('mouseup',this.touchEnd)
		//document.querySelector('.swiper-xscroll>div').classList.add('active')
		window.addEventListener('resize', this.vdChange)
		this.swipeDom()
		this.pagList()
		setTimeout(this.activeChg,1000,2)
		
    }
	componentWillUnmount(){
		window.removeEventListener('resize', this.vdChange)
    }
	render() {
		return (
				<div id = {`${this.state.parentObj.id}`}>
					<div className='swiper-pagination'>
						{this.state.paginations}
					</div>
					<div className="swiper-slide opacity-swiper" >
						<div onScroll={this.xscrollChange} className="swiper-xscroll">
							{this.state.sildes}
						</div>
					</div>
					<div className="swiper-btns">
						<button className ="prev-btn move-btn" onClick={this.prevBtn}>
							<img src = "./ServiceAi/prevBtn.png" alt = ""/>	
						</button>	
						<button className ="next-btn move-btn" onClick={this.nextBtn}>
							<img src = "./ServiceAi/nextBtn.png" alt = ""/>
						</button>
					</div>
				</div>
		);
	
}}


export default OpacitySwipe