import React from 'react'
import './About.css'
import 'animate.css';
import Menu from '../Home/menu.js'
import Footer from '../footer.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faLocationDot, faPhone, faFax , faEnvelope } from '@fortawesome/free-solid-svg-icons'
class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vdSrc: "./About/CI.mp4",
			sectionAnimate : [false, false, false, false, false]
		};

		this.vdChange = this.vdChange.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.locationChange01 = this.locationChange01.bind(this);
		this.locationChange02 = this.locationChange02.bind(this);
		this.scrollFunc = this.scrollFunc.bind(this);
		this.topBtnFunc = this.topBtnFunc.bind(this);

		if(window.outerWidth<1025){
			this.state.vdSrc = "./About/CI_Tablet.mp4";
		}
		else if(window.outerWidth<425){
			this.state.vdSrc = "./About/CI_Mobile.mp4";
		}
	}

	/* 스크롤시 top button 보이기 */
	scrollFunc = () => {
		const topBtn = document.querySelector('.top-btn');
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		const currentTop = scrollTarget.scrollTop > window.scrollY?scrollTarget.scrollTop:window.scrollY
		var winheight = window.innerHeight;

		if (currentTop >= winheight) {
			topBtn.style.display = "block";
		} else {
			topBtn.style.display = "none";
		}
	}

	/* top button 클릭시 화면 상단이동 */
	topBtnFunc = () => {
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		scrollTarget.scrollTop = 0
		window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
	}

	vdChange = event =>{
		const video = document.getElementById('ciVd')
		if(window.outerWidth> 1024 && this.state.vdSrc !== "./About/CI.mp4"){
			this.setState({
				vdSrc: "./About/CI.mp4"
			});
			video.load()
		}
		else if(window.outerWidth> 425&& window.outerWidth < 1024  && this.state.vdSrc !== "./About/CI_Tablet.mp4"){
			this.setState({
				vdSrc: "./About/CI_Tablet.mp4"
			});
			video.load()
		}
		else if(window.outerWidth< 425 && this.state.vdSrc !== "./About/CI_Mobile.mp4"){
			this.setState({
				vdSrc: "./About/CI_Mobile.mp4"
			});
			video.load()
		}
	}

	// 스크롤이 위치 감지 애니메이션 이벤트
	onScroll = event => {
		this.scrollFunc()
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		if((window.scrollY/scrollTarget.scrollHeight)>0.62){
			if(!this.state.sectionAnimate[4]){
				const p4Ani = document.querySelector('.scroll-home-fourth')
				p4Ani.querySelector('.fourth-title').classList.add('animate__animated')
				p4Ani.querySelector('.fourth-title').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__animated')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__delay-1s')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__animated')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__delay-1s')
				const p4AniLi = p4Ani.querySelectorAll('li')
				for(let i=0; i<p4AniLi.length; i++) {
					p4AniLi[i].classList.add('animate__animated')
					p4AniLi[i].classList.add('animate__fadeInUp')
					p4AniLi[i].classList.add('animate__delay-2s')
				}
			}
			this.setState({
				sectionAnimate : [true, true, true, true, true]
			});
		}

		else if((window.scrollY/scrollTarget.scrollHeight)>0.5){
				if(!this.state.sectionAnimate[3]){
				document.querySelector('.ci_02').classList.add('animate__animated')
				document.querySelector('.ci_02').classList.add('animate__fadeInUp')
			}
			this.setState({
				sectionAnimate : [true, true, true, true, false]
			});
		}

		else if((window.scrollY/scrollTarget.scrollHeight)>0.38){
				if(!this.state.sectionAnimate[2]){
				document.querySelector('.ci_01').classList.add('animate__animated')
				document.querySelector('.ci_01').classList.add('animate__fadeInUp')
			}
			this.setState({
				sectionAnimate : [true, true, true, false, false]
			});
		}

		else if((window.scrollY/scrollTarget.scrollHeight)>0.15){
				if(!this.state.sectionAnimate[1]){
				const p2Ani = document.querySelector('.about-page-second')
				const p2AniLi = p2Ani.querySelectorAll('li')
				for(let i=2; i<5; i++) {
					p2AniLi[i].classList.add('animate__animated')
					p2AniLi[i].classList.add('animate__fadeInUp')
					p2AniLi[i].classList.add(`animate__delay-${i+1}s`)
				}
			}
			this.setState({
				sectionAnimate : [true, true, false, false, false]
			});
		}
		
		else if((window.scrollY/scrollTarget.scrollHeight)>0.1){
				if(!this.state.sectionAnimate[0]){
				const p2Ani = document.querySelector('.about-page-second')
				const p2AniLi = p2Ani.querySelectorAll('li')
				for(let i=0; i<2; i++) {
					p2AniLi[i].classList.add('animate__animated')
					p2AniLi[i].classList.add('animate__fadeInUp')
					p2AniLi[i].classList.add(`animate__delay-${i+1}s`)
				}
			}
			this.setState({
				sectionAnimate : [true, false, false, false, false]
			});
		}
		
	}
	// LOCATION 버튼 기능
	locationChange01 = () => {
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		const loactionHead = document.querySelector('.l-headquarters')
		const loactionRD = document.querySelector('.l-r-d-space')
		locationBtn1.style.opacity ='1'
		locationBtn2.style.opacity ='0.3'
		loactionHead.style.display = 'flex'
		loactionRD.style.display = 'none'
	}
	locationChange02 = () => {
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		const loactionHead = document.querySelector('.l-headquarters')
		const loactionRD = document.querySelector('.l-r-d-space')
		locationBtn1.style.opacity ='0.3'
		locationBtn2.style.opacity ='1'
		loactionRD.style.display = 'flex'
		loactionHead.style.display = 'none'
	}

	componentDidMount(){
		window.addEventListener('resize', this.vdChange)
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		scrollTarget.addEventListener('scroll', this.onScroll);
		window.addEventListener('scroll',this.onScroll)
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		locationBtn1.addEventListener('click',this.locationChange01)
		locationBtn2.addEventListener('click',this.locationChange02)
		const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container-scrollpage')
		wrapContainer.addEventListener('scroll', this.onScroll)
		topBtn.addEventListener('click',this.topBtnFunc)
    }
    
    componentWillUnmount(){
		window.removeEventListener('resize', this.vdChange)
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		scrollTarget.removeEventListener('scroll', this.onScroll);
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		locationBtn1.removeEventListener('click',this.locationChange01)
		locationBtn2.removeEventListener('click',this.locationChange02)
		window.removeEventListener('scroll',this.onScroll)
		const topBtn = document.querySelector('.top-btn');
		topBtn.removeEventListener('click',this.topBtnFunc)
    }
    render() {
    return (
    <div className="wrap-container-about menu-color-cg wrap-container-scrollpage" onScroll={this.onScroll}>
    <Menu/>
    <section className="about-page-first" >
		<div className="circle-box">
        <div className="circle">
            <p>WHY CHOOSE US.</p>
            <span>
            (주)오앤은 전문적인 IT 기술과 서비스 및 인공 지능 기술을 통해 고객과 함께 성장하는 IT Partner 입니다.
            </span>
        </div>
        <div className="circle circle02 animate__animated animate__fadeInUp "> 
            <p>OUR MISSION.</p>
            <span>
            (주)오앤은 IT 서비스 전 분야에서 축적한 경험을 토대로 인공 지능, 클라우드, IoT 등의 신기술을 접목하여 고객과, 사용자와 함께 성장합니다.
            </span>
        </div>
        <div className="circle circle03 animate__animated animate__fadeInUp animate__delay-1s"> 
            <p>WHAT WE DO.</p>
            <span>
            IT 보안 진단 및 컨설팅, 시스템 구축, 시스템 관리 
			운영과 유지보수, 인공 지능 분석기술-영상-음성-
			텍스트, 자사 서비스 개발 운영 및 클라우드 
			컨설팅 및 이관, 프로젝트 구축 매니지드 서비스를 
			제공하고 있습니다.
			</span>
        </div>
        </div>
    </section>
    <section className="about-page-second">
        <p>
        HISTORY
        </p>
		<ul className="history-box">
			<div></div>
            <li>
				<dt>
				2022
				</dt>
				<dd>탐앤탐스, 인공 지능 영상 분석 솔루션 ‘다이비티’ 리테일 인공 지능 솔루션 POC</dd>
				<dd>KPNP 차세대 통합 시스템 구축 및 인공 지능 영상 분석 기술 적용</dd>
				<dd>신한은행 디지로그 시스템 구축 참여</dd>
				<dd>공사 현장 안전 관리, 인공 지능 영상 분석 솔루션 ‘다이비티’ 스페이스 POC</dd>
				<dd>스포츠 다이어리 시스템 고도화 - 승마, 수영, 레슬링, 유도, 한체대, 이천선수촌, 테니스</dd>
				<dd>디지털 대면 영상 회의 솔루션 ‘루프’ 버전 3 고도화 완료</dd>
				<dd>(주)오앤 기술평가 TCB T4 등급 획득, 인공 지능 영상 분석 기술 TRL 8</dd>
				<dd>(주)오앤 기업부설연구소 재인증 완료</dd>
				<dd>(주)오앤 벤처 기업 재인증 완료</dd>
            </li>
            <li>
            <dt>
				2021
				</dt>
				<dd>지능형 라이브 방송 솔루션 개발, 운영 - 태권도 국내-국제 경기운영</dd>
				<dd>KG EDUONE 쌤플러스 통합 사이트 리뉴얼</dd>
				<dd>KG EDUONE ITBANK LIVE 화상 교육 솔루션 도입</dd>
				<dd>미래에셋 화상 교육 솔루션 도입</dd>
				<dd>농축협, 저축은행중앙회 화상 교육 솔루션 도입</dd>
				<dd>JTBC 100명+ 온라인 화상 솔루션 방송 도입</dd>
				<dd>글로벌 그룹 CUE 와 인공 지능 영상 분석 기술협업 MOU 체결</dd>
				<dd>K-비대면바우처 화상회의 및 협업분야 공급사 선정, 화상 솔루션 및 서비스 제공</dd>
				<dd>KPNP 세계 태권도연맹, 아시아 태권도 연맹 및 국제경기용 인공 지능 영상 분석 기술 도입 소개</dd>
				<dd>KPNP 와 전략적 파트너십 체결 및 온라인 품새 경기 자동 스트리밍 시스템 개발, 운영</dd>
            </li>
            <li>
				<dt>
				2020
				</dt>
				<dd>인공 지능 스포츠 영상 분석 솔루션 개발, 배드민턴 경기 운영</dd>
				<dd>디지털 대면 화상 솔루션 ‘루프’ 개발 완료 서비스 제공</dd>
				<dd>인공 지능 기술기반 스포츠 영상 분석, 자동 스코어링, 에러-반칙 탐지, 방송 송출 시스템 개발</dd>
				</li>
				<li>
				<dt>
				2019
				</dt>
				<dd>인공 지능 영상 분석 휴먼 행동 분석 시스템 개발 완료, TRL 6</dd>
				<dd>인공 지능 영상 분석 객체 탐지 및 추척기술 개발 완료, TRL 6</dd>
            </li>
            <li>
				<dt>
				2018
				</dt>
				<dd>인공 지능 코딩 교육 플랫폼 개발 완료, 서비스</dd>
				<dd>(주)오앤 벤처 기업 재인증 완료</dd>
				<dd>유아 두상교정을 위한 인공 지능 영상 분석 및 IoT 서비스 제품 개발 및 특허 등록 완료</dd>
				<dd>인공 지능 분야 첨단기술상 대상 수상</dd>
            </li>
        </ul>
    </section>
    <section className="about-page-third">
        <div className = "video-bg">
					<video id = "ciVd" muted autoPlay loop>
						<source src={this.state.vdSrc} type="video/mp4"/>
					</video>
				</div>
        <div className = "ci ci_01">
            <span>
            사람을 위해,<br/> 사람을 탐구합니다.
            </span>
            <p>
            위험으로부터 안전할 수 있도록,<br/>
            신체적 변화 관리로 보다 높은 꿈을 실현할 수 있도록,<br/>
            행동 분석을 통해 보다 나은 내일을 맞이할 수 있도록,<br/>
            눈으로 볼 수 있는 모든 것의 데이터 분석을<br/>
            기본으로 기술 개발을 시작합니다.
            </p>
            <p>
            2014 ~ 2021
            </p>
        </div>
    </section>
    <section className="about-page-fourth">
        <div className = "ci ci_02">
            <span>
            이곳에서부터, 따로 또 같이
            </span>
            <p>
            최고는 아니지만 최선을 더해 지속적 기술력 고도화를 
            실현하고, 크지는 않지만 유기적 파워풀함을 제공합니다.
            </p>
            <p>
            2022 ~
            </p>
            <img src="./5n-ci-logo.png" alt=""/>
        </div>
        
    </section>
    <section className = "fourth-section">
				<div className = "scroll-home-fourth">
					<p className = "fourth-title">LOCATION</p>
					<div className="location-btns">
						<button type="button">Headquarters</button>
						<button type="button">Live R&D Space</button>
					</div>
					<div className = "info-box l-headquarters">
						<div className = "location-5n">
							<div className = "location-wrap location-wrap-01" >
							<iframe title="location-5n" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d559.1177925636148!2d126.82381591121292!3d37.55864806943031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9db051721af5%3A0x251fe32aa83d09ed!2z66eI6rOh7Jeg66as7LK0!5e0!3m2!1sko!2skr!4v1676541612997!5m2!1sko!2skr"></iframe>
							</div>
							
						</div>
						<ul>
							<li>
								<p>
									<FontAwesomeIcon icon={faLocationDot} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(주)오앤 & 기업부설연구소
								</p>
								<span>
									서울특별시 강서구 마곡중앙1로 26 마곡엠리체 6층 604호<br/>
									Headquarters : 6F 604, 26, Magokjungang 1-ro, Gangseo-gu, Seoul, Republic of Korea
								</span>
							</li>
							<ul>
								<li>
									<FontAwesomeIcon icon={faPhone} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 02 2661 5557
								</li>
																<li>
									<FontAwesomeIcon icon={faFax} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 0303 3440 7612
								</li>
																<li>
									<FontAwesomeIcon icon={faEnvelope} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;proposal@5n.co.kr
								</li>
							</ul>
						</ul>
					</div>
					<div className = "info-box l-r-d-space">
						<div className = "location-5n">
							<div className = "location-wrap location-wrap-02" >
								<img src = "./Home/5n-R_D-SPACE-map.png" alt='5n'/>
							</div>
						</div>
						<ul>
							<li>
								<p>
									<FontAwesomeIcon icon={faLocationDot} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(주)오앤 라이브 R&D SPACE
								</p>
								<span>
									제주특별자치도 서귀포시 서호동 1615-11 테트라필드 제주라이브 빌딩 5층 521호<br/>
									Live R&D Space : 521 Live Bdg2, 1615-11, Seoho-dong, Seogwipo-si, Jeju-do, Republic of Korea
								</span>
							</li>
							<ul>
								<li>
									<FontAwesomeIcon icon={faPhone} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 02 2661 5557
								</li>
																<li>
									<FontAwesomeIcon icon={faFax} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 0303 3440 7612
								</li>
																<li>
									<FontAwesomeIcon icon={faEnvelope} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;proposal@5n.co.kr
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</section>
			<button type="button" className='top-btn'><img src = "./Home/TOP-BTN.png" alt=''/></button>
		<Footer/>
	</div>
)
}}

export default About