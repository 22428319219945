//import React from 'react';
import React from 'react'
import '../../App.css';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faLocationDot, faPhone, faFax , faEnvelope } from '@fortawesome/free-solid-svg-icons'
import XscrollPage from '../XscrollPage';
import Menu from './menu.js'
import Footer from '../footer.js'
import {Link} from 'react-router-dom';
class Home extends React.Component  {
	constructor(props) {
		super(props);
		this.state = {
			vdSrc: "./Home/new-5n-main.mp4",
			sectionAnimate : [false, false, false],
			slideNum:0,
			interStop:0
		};
		this.vdChange = this.vdChange.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.xScroll = this.xScroll.bind(this);
		this.locationChange01 = this.locationChange01.bind(this);
		this.locationChange02 = this.locationChange02.bind(this);
		this.scrollFunc = this.scrollFunc.bind(this);
		this.topBtnFunc = this.topBtnFunc.bind(this);
		//this.ClearInt = this.ClearInt.bind(this);
		
		if(window.outerWidth<426){
			this.state.vdSrc = "./Home/new-mobile_main1.mp4";
		}
		else if(window.outerWidth<1025){
			this.state.vdSrc = "./Home/new-tablet_main1_hover.mp4";
		}
	}

	/* 스크롤시 top button 보이기 */
	scrollFunc = () => {
		const topBtn = document.querySelector('.top-btn');
		//var winheight = window.innerHeight;
		const wrapContainer = document.querySelector('.wrap-container')
		var winheight = window.innerHeight;
		//window.onscroll = function() {scrollFunc()};
		if (wrapContainer.scrollTop >= winheight) {
			topBtn.style.display = "block";
		} else {
			topBtn.style.display = "none";
		}
	}

	/* top button 클릭시 화면 상단이동 */
	topBtnFunc = () => {
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.scrollTop = 0;
	}

	/* 자동 스크롤링 */	
	swiperBtnAuto = () => {
		const xscroll = document.getElementById('corp-company');
		var xwidth = xscroll.querySelector('.scroll-home-second-xscroll').offsetWidth;
		const i = this.state.slideNum
		this.setState({slideNum:(i+1)%6})
		
		//if(this.state.slideNum >= 6){this.state.slideNum=0}
		xscroll.querySelector('.scroll-home-second-xscroll').scrollTo({
			top: 0,
			left: this.state.slideNum*xwidth,
			behavior: 'smooth'
		})
	}

	
	vdChange = event =>{
		const video = document.getElementById('vid')
		if(window.outerWidth> 1024 && this.state.vdSrc !== "./Home/new-mobile_main1.mp4"){
			this.setState({
				vdSrc: "./Home/new-5n-main.mp4"
			});
			video.load()
		}
		else if(window.outerWidth> 425 && window.outerWidth < 1024  && this.state.vdSrc !== "./Home/new-tablet_main1_hover.mp4"){
			this.setState({
				vdSrc: "./Home/new-tablet_main1_hover.mp4"
			});
			video.load()
		}
		else if(window.outerWidth < 425  && this.state.vdSrc !== "./Home/new-mobile_main1.mp4"){
			this.setState({
				vdSrc: "./Home/new-mobile_main1.mp4"
			});
			video.load()
		}
		
	}

	// 스크롤이 위치 감지 애니메이션 이벤트
	onScroll = event => {
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		
		if((scrollTarget.scrollTop/scrollTarget.scrollHeight)>0.5){
			if(!this.state.sectionAnimate[2]){
				const p4Ani = document.querySelector('.scroll-home-fourth')
				p4Ani.querySelector('.fourth-title').classList.add('animate__animated')
				p4Ani.querySelector('.fourth-title').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__animated')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-01').classList.add('animate__delay-1s')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__animated')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__fadeInUp')
				p4Ani.querySelector('.location-wrap-02').classList.add('animate__delay-1s')
				const p4AniLi = p4Ani.querySelectorAll('li')
				for(let i=0; i<p4AniLi.length; i++) {
					p4AniLi[i].classList.add('animate__animated')
					p4AniLi[i].classList.add('animate__fadeInUp')
					p4AniLi[i].classList.add('animate__delay-2s')
				}
			}
			this.setState({
				sectionAnimate : [true, true, true]
			});
		}
		else if((scrollTarget.scrollTop/scrollTarget.scrollHeight)>0.35){
				if(!this.state.sectionAnimate[1]){
				const p3Ani = document.querySelector('.scroll-home-third')
				p3Ani.querySelector('p').classList.add('animate__animated')
				p3Ani.querySelector('p').classList.add('animate__fadeInUp')
				const p3AniLi = p3Ani.querySelectorAll('.img-box')
				for(let i=0; i<p3AniLi.length; i++) {
					p3AniLi[i].classList.add('animate__animated')
					p3AniLi[i].classList.add('animate__fadeInUp')
					p3AniLi[i].classList.add('animate__delay-1s')
				}
			}
			this.setState({
				sectionAnimate : [true, true, false]
			});
		}
		else if((scrollTarget.scrollTop/scrollTarget.scrollHeight)>0.15){
				if(!this.state.sectionAnimate[0]){
				document.querySelector('.scroll-home-second-box>div>span, .scroll-home-second-box>div>p').classList.add('animate__animated')
				document.querySelector('.scroll-home-second-box>div>span').classList.add('animate__fadeInUp')
				document.querySelector('.scroll-home-second-box>div>p').classList.add('animate__animated')
				document.querySelector('.scroll-home-second-box>div>p').classList.add('animate__fadeInUp')
				const p2Ani = document.querySelector('.scroll-home-second-text')
				const p2AniLi = p2Ani.querySelectorAll('div')
				for(let i=0; i<p2AniLi.length; i++) {
					p2AniLi[i].classList.add('animate__animated')
					p2AniLi[i].classList.add('animate__fadeInUp')
					p2AniLi[i].classList.add(`animate__delay-${i+1}s`)
				}
			}
			this.setState({
				sectionAnimate : [true, false, false]
			});
		}
	}
	xScroll = () => {
		if(window.outerWidth> 1024){
			return 0;
		}
		const video = document.getElementById('vid')
		const xwidth = document.getElementById('scroll-home-first').querySelector('.swiper-slide')
		const xposition = document.getElementById('scroll-home-first').querySelector('.scroll-home-second-xscroll')
		const page = xposition.scrollLeft/xwidth.scrollWidth*1.1
		if(window.outerWidth> 425){
			if(page>2 && this.state.vdSrc!== './Home/tablet_main3_hover.mp4' ){
			this.setState({
				vdSrc: "./Home/tablet_main3_hover.mp4"
			});
			video.load()
			}
			else if(page>1 && page <2 && this.state.vdSrc!== './Home/new-tablet_main1_hover.mp4'){
			this.setState({
				vdSrc: "./Home/new-tablet_main1_hover.mp4"
			});
			video.load()
			}
			else if(page<1 && this.state.vdSrc!== './Home/tablet_main1_hover.mp4'){
			this.setState({
				vdSrc: "./Home/tablet_main1_hover.mp4"
			});
			video.load()
			}
		}
		else{
			if(page>2 && this.state.vdSrc!== './Home/mobile_main3.mp4' ){
			this.setState({
				vdSrc: "./Home/mobile_main3.mp4"
			});
			video.load()
			}
			else if(page>1 && page <2 && this.state.vdSrc!== './Home/new-mobile_main1.mp4'){
			this.setState({
				vdSrc: "./Home/new-mobile_main1.mp4"
			});
			video.load()
			}
			else if(page<1 && this.state.vdSrc!== './Home/mobile_main1.mp4'){
			this.setState({
				vdSrc: "./Home/mobile_main1.mp4"
			});
			video.load()
			}
		}
	}
	// LOCATION 버튼 기능
	locationChange01 = () => {
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		const loactionHead = document.querySelector('.l-headquarters')
		const loactionRD = document.querySelector('.l-r-d-space')
		locationBtn1.style.opacity ='1'
		locationBtn2.style.opacity ='0.3'
		loactionHead.style.display = 'flex'
		loactionRD.style.display = 'none'
	}
	locationChange02 = () => {
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		const loactionHead = document.querySelector('.l-headquarters')
		const loactionRD = document.querySelector('.l-r-d-space')
		locationBtn1.style.opacity ='0.3'
		locationBtn2.style.opacity ='1'
		loactionRD.style.display = 'flex'
		loactionHead.style.display = 'none'
	}

	componentDidMount(){
		window.addEventListener('resize', this.vdChange)
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		scrollTarget.addEventListener('scroll', this.onScroll);
		const xwidth = document.getElementById('scroll-home-first').querySelector('.scroll-home-second-xscroll')
		xwidth.addEventListener('scroll', this.xScroll)
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		locationBtn1.addEventListener('click',this.locationChange01)
		locationBtn2.addEventListener('click',this.locationChange02)
		const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.addEventListener('scroll', this.scrollFunc)
		topBtn.addEventListener('click',this.topBtnFunc)		
		const btnContainer = document.querySelector('#corp-company .swiper-btn-container')
		btnContainer.addEventListener('click',()=>{
			this.setState({interStop:1})
		})
		this.interval = setInterval(() =>{
			this.swiperBtnAuto()
		},5000)
	}

	componentDidUpdate(){
		if(this.state.interStop){
			clearInterval(this.interval)
		}
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.vdChange)
		const scrollTarget = document.querySelector('.wrap-container-scrollpage')
		scrollTarget.removeEventListener('scroll', this.onScroll);
		const xwidth = document.getElementById('scroll-home-first').querySelector('.scroll-home-second-xscroll')
		xwidth.removeEventListener('scroll', this.xScroll)
		const locationBtn1 = document.querySelector('.location-btns button:nth-child(1)')
		const locationBtn2 = document.querySelector('.location-btns button:nth-child(2)')
		locationBtn1.removeEventListener('click',this.locationChange01)
		locationBtn2.removeEventListener('click',this.locationChange02)
		const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.removeEventListener('scroll', this.scrollFunc)
		topBtn.removeEventListener('click',this.topBtnFunc)
		clearInterval(this.interval)
    }

	render() {
	return (
		
		<div className="wrap-container-scrollpage wrap-container-home menu-color-cg wrap-container">
			<Menu/>
			<section>
				<div className = "video-bg">
					<video id = "vid" muted autoPlay loop>
						<source src={this.state.vdSrc} type="video/mp4"/>
					</video>
				</div>
				<XscrollPage id = "scroll-home-first" width ="100vw">
					<div className ="scroll-home-first-box">
						<div className ="animate__animated animate__fadeInUp">
							<span> 5N’s A.I. Vision <br/>Solution DYVITY</span>
							<p>
								인공 지능 영상 분석 기술을 기반으로 하는 다양한 서비스를 제공합니다.
							</p>
							<Link to="/ServiceAI">자세히 보기</Link>
							{/* <div className='voucher-banner'>
								<img src = "./Home/voucher-banner.png" alt=''/>
							</div> */}
							<div className='box-hover-pic'>
								<img src = "./Home/dyvity-img.png" alt=''/>
							</div>
						</div>
						<div className='voucher-banner'>
							<img src = "./Home/voucher-banner.png" alt=''/>
						</div>
					</div>
					<div className ="scroll-home-first-box">
						<div className ="animate__animated animate__fadeInUp">
							<span> IoT & M2M</span>
							<p>
								MCU-PCB 제작부터 펌웨어 App, Web 서비스까지 한번에 최적화된 시스템을 제공합니다.
							</p>
							<Link to="/IotM2M">자세히 보기</Link>
							<div className='box-hover-pic'>
								<img src = "./Home/first-box-pic2.png" alt=''/>
							</div>
						</div>
					</div>
					<div className ="scroll-home-first-box">
						<div className ="animate__animated animate__fadeInUp">
							<span> Video Conferencing<br/>& Webinars</span>
							<p>
								웹 브라우저에 최적화되어 개발된 디지털 대면 솔루션을 통해 소규모부터 대규모 모임을 온라인에서 끊김 없이 깨끗한 음질을 제공합니다.
							</p>
							<Link to=""  onClick={function() {alert('서비스 준비중입니다.')}}>데모 신청</Link>
						</div>
					</div>
				</XscrollPage>
			</section>
			<section >
				<div className = "scroll-home-second">
					<div className ="scroll-home-second-box">
						<div>
							<span>최상의 IT 기술들을 
								<span>만들어 갑니다.</span>
							</span>
							<p>
								인공 지능 분석 기술을 접목한 서비스, IoT 기술을 활용한
								하드웨어와 소프트웨어, 새롭고 안정적이며 최상의 퍼포먼스를
								낼 수 있도록 하는 기술들을 연구하고 구현합니다.
							</p>
						</div>
					</div>
					<XscrollPage id = "explanation-5n" width ="58vw" btnNum="2">
							<div className ="scroll-home-second-text">
								<div >
									<span> SYSTEM INTEGRATION</span>
									<p>
										대규모 시스템 구축/운영 경험과 전문화된 조직을 구성으로 IT 컨설팅부터 기획, 설계, 개발, 운영까지 Total IT 서비스를 제공합니다.							
									</p>
									<hr/>
								</div>
								<div >
									<span>A.I. VISION</span>
									<p>
										인공 지능 영상 분석 기술을 기반으로 하는 다양한 산업군의 서비스를 제공합니다.							
									</p>
									<hr/>
								</div>
								<div >
									<span> VIDEO CONFERENCING & WEBINARS</span>
									<p>
										영상을 통한 온라인 디지털대면 회의 및 교육 솔루션을 통해 끊김 없고 깨끗한 품질의 서비스를 제공합니다.							
									</p>
									<hr/>
								</div>
							</div>
							<div className ="scroll-home-second-text">
								<div >
									<span>CLOUD SUPPORT</span>
									<p>
									다양한 클라우드 환경 및 프로세스의 인프라 혁신과 효율적이고 합리적인 클라우드 서버운영을 지원합니다.					
									</p>
									<hr/>
								</div>
								<div >
									<span>IOT & M2M CORE</span>
									<p>
									IoT 설계, 제작부터 판매까지, 최상의 핵심 기술 설계로 보답합니다.					
									</p>
									<hr/>
								</div>
								<div >
									<span>EXTENSIVE DEVELOPMENT</span>
									<p>
									웹 서비스 및 앱, 임베디드 및 응용프로그램 및 솔루션까지 다양한 분야에서 요구되는 기술을 제공합니다.				
									</p>
									<hr/>
								</div>
							</div>
					</XscrollPage>
				</div>
			</section>
			<section >
				<div className = "scroll-home-third">
					<p>OUR CLIENTS</p>
					<XscrollPage id = "corp-company" width = "95%" btnNum = "6">
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/39.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/1.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/9.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/5.png" alt=''/>
									</div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/4.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/7.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/45.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/46.png" alt=''/>
									</div>
								</li>
							</ul>
						</div>
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/3.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/10.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/11.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/12.png" alt=''/>
									</div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/13.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/14.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/15.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/16.png" alt=''/>
									</div>
								</li>
							</ul>
						</div>
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/47.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/22.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/23.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/24.png" alt=''/>
									</div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/25.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/26.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/27.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/28.png" alt=''/>
									</div>
								</li>
							</ul>
						</div>
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/29.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/30.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/31.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/32.png" alt=''/>
									</div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/33.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/34.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/35.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/36.png" alt=''/>
									</div>
								</li>
							</ul>
						</div>
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/6.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/8.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/2.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/40.png" alt=''/>
									</div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/41.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/42.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/43.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/44.png" alt=''/>
									</div>
								</li>
							</ul>
						</div>
						<div className = "corp-wrap">
							<ul>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/37.png" alt=''/>
									</div>
									<div className = "img-box">
										<img src = "./corp-img/38.png" alt=''/>
									</div>
								</li>
								<li>
									<div className = "img-box">
										<img src = "./corp-img/48.png" alt=''/>
									</div>
									<div className = "img-box"></div>
								</li>
							</ul>
							<ul>
								<li>
									<div className = "img-box"></div>
									<div className = "img-box"></div>
								</li>
								<li>
									<div className = "img-box"></div>
									<div className = "img-box"></div>
								</li>
							</ul>
						</div>
					</XscrollPage>
				</div>
			</section>
			<section className = "fourth-section">
				<div className = "scroll-home-fourth">
					<p className = "fourth-title">LOCATION</p>
					<div className="location-btns">
						<button type="button">Headquarters</button>
						<button type="button">Live R&D Space</button>
					</div>
					<div className = "info-box l-headquarters">
						<div className = "location-5n">
							<div className = "location-wrap location-wrap-01" >
								<img src = "./Home/5n-Headquarters.png" alt='5n'/>
							</div>
						</div>
						<ul>
							<li>
								<p>
									<FontAwesomeIcon icon={faLocationDot} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(주)오앤 & 기업부설연구소
								</p>
								<span>
									서울특별시 강서구 마곡중앙1로 26 마곡엠리체 6층 604호<br/>
									Headquarters : 6F 604, 26, Magokjungang 1-ro, Gangseo-gu, Seoul, Republic of Korea
								</span>
							</li>
							<ul>
								<li>
									<FontAwesomeIcon icon={faPhone} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 02 2661 5557
								</li>
																<li>
									<FontAwesomeIcon icon={faFax} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 0303 3440 7612
								</li>
																<li>
									<FontAwesomeIcon icon={faEnvelope} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;proposal@5n.co.kr
								</li>
							</ul>
						</ul>
					</div>
					<div className = "info-box l-r-d-space">
						<div className = "location-5n">
							<div className = "location-wrap location-wrap-02" >
								<img src = "./Home/5n-R_D-SPACE.png" alt='5n'/>
							</div>
						</div>
						<ul>
							<li>
								<p>
									<FontAwesomeIcon icon={faLocationDot} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(주)오앤 라이브 R&D SPACE
								</p>
								<span>
									제주특별자치도 서귀포시 서호동 1615-11 테트라필드 제주라이브 빌딩 5층 521호<br/>
									Live R&D Space : 521 Live Bdg2, 1615-11, Seoho-dong, Seogwipo-si, Jeju-do, Republic of Korea
								</span>
							</li>
							<ul>
								<li>
									<FontAwesomeIcon icon={faPhone} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 02 2661 5557
								</li>
																<li>
									<FontAwesomeIcon icon={faFax} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;(82) 0303 3440 7612
								</li>
																<li>
									<FontAwesomeIcon icon={faEnvelope} size="1x" color="#0c75ff"/>
									&nbsp;&nbsp;&nbsp;proposal@5n.co.kr
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</section>
			<button type="button" className='top-btn'><img src = "./Home/TOP-BTN.png" alt=''/></button>
			<Footer/>
		</div>
	
	);
}}

export default Home