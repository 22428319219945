import React from 'react'
import './Recruit.css'
import 'animate.css';
import Menu from '../Home/menu.js'
import Footer from '../footer.js'
import XscrollPage from '../XscrollPage';

class Recruit extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        sectionAnimate : [false, false, false]
    };

    this.onScroll = this.onScroll.bind(this);
    this.scrollFunc = this.scrollFunc.bind(this);
	this.topBtnFunc = this.topBtnFunc.bind(this);

    }
    /* 스크롤시 top button 보이기 */
	scrollFunc = () => {
		const scrollTarget = document.querySelector('.wrap-container')
		const currentTop = scrollTarget.scrollTop > window.scrollY?scrollTarget.scrollTop:window.scrollY
		const topBtn = document.querySelector('.top-btn');
		var winheight = window.innerHeight;
		if (currentTop >= winheight) {
			topBtn.style.display = "block";
		} else {
			topBtn.style.display = "none";
		}
	}

	/* top button 클릭시 화면 상단이동 */
	topBtnFunc = () => {
		window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
		

	}

    // 스크롤이 위치 감지 애니메이션 이벤트
	onScroll = event => {
        this.scrollFunc()
		const scrollTarget = document.querySelector('.wrap-container')
		if((window.scrollY/scrollTarget.scrollHeight)>0.6){
			if(!this.state.sectionAnimate[2]){
				document.querySelector('.recruit-fourth header').classList.add('animate__animated')
				document.querySelector('.recruit-fourth header').classList.add('animate__fadeInUp')

                document.querySelector('.job-postings-list').classList.add('animate__animated')
                document.querySelector('.job-postings-list').classList.add('animate__fadeInUp')
                document.querySelector('.job-postings-list').classList.add('animate__delay-1s')
			}
			this.setState({
				sectionAnimate : [true, true, true]
			});
		}
		else if((window.scrollY/scrollTarget.scrollHeight)>0.36){
				if(!this.state.sectionAnimate[1]){
				document.querySelector('.recruit-thrid header').classList.add('animate__animated')
				document.querySelector('.recruit-thrid header').classList.add('animate__fadeInUp')

                const p3AniLi = document.querySelectorAll('.welfare')
				for(let i=0; i<p3AniLi.length; i++) {
					p3AniLi[i].classList.add('animate__animated')
					p3AniLi[i].classList.add('animate__fadeInUp')
					p3AniLi[i].classList.add('animate__delay-1s')
				}
			}
			this.setState({
				sectionAnimate : [true, true, false]
			});
		}
		else if((window.scrollY/scrollTarget.scrollHeight)>0.1){
				if(!this.state.sectionAnimate[0]){
				document.querySelector('.recruit-second header').classList.add('animate__animated')
				document.querySelector('.recruit-second header').classList.add('animate__fadeInUp')
				
				const p2Ani = document.querySelector('.recruit-second')
				const p2AniLi = p2Ani.querySelectorAll('.careers-box')
				for(let i=0; i<p2AniLi.length; i++) {
					p2AniLi[i].classList.add('animate__animated')
					p2AniLi[i].classList.add('animate__fadeInUp')
					p2AniLi[i].classList.add('animate__delay-1s')
				}
			}
			this.setState({
				sectionAnimate : [true, false, false]
			});
		}
	}

    componentDidMount(){
		window.addEventListener('scroll', this.onScroll);
        const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.addEventListener('scroll', this.scrollFunc)
		topBtn.addEventListener('click',this.topBtnFunc)
    }
    componentWillUnmount(){
		window.removeEventListener('scroll', this.onScroll);
        const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.removeEventListener('scroll', this.scrollFunc)
		topBtn.removeEventListener('click',this.topBtnFunc)
    }

    render() {
        return (
        <div className="recruit menu-color-cg wrap-container">
            <Menu/>
            <section className="recruit-first">
                <header className='animate__animated animate__fadeInUp'>
                    <span>TALENT</span>
                    <p>
                        (주)오앤에서 도전 정신, 신뢰성, 창의성을<br/>
                        갖춘 인재를 소중하게 여깁니다.
                    </p>
                </header>
                <XscrollPage id = "talent" btnNum = "3" >
                    <div className="talent-box animate__animated animate__fadeInUp animate__delay-1s">
                        <div/>
                        <img src = "./Recruit/img1.png" alt = ""/>
                        <p> 도전정신</p>
                        <span>
                            급변하는 사회에서 어떤 방향으로 기술이 변하는 지 
                            끊임없이 연구하고, 새로운 기술을 확보합니다.
                        </span>
                    </div>
                    <div className="talent-box animate__animated animate__fadeInUp animate__delay-1s">
                        <div/>
                        <img src = "./Recruit/img2.png" alt = ""/>
                        <p> 신뢰성</p>
                        <span>
                            신뢰성은 스스로를 믿고, 함께 일하는 동료를 믿는 것입니다. 
                            서로를 믿고 공감하는 과정에서 협업이 되고 일의 시너지 효과를 냅니다.
                        </span>
                    </div>
                    <div className="talent-box animate__animated animate__fadeInUp animate__delay-1s">
                        <div/>
                        <img src = "./Recruit/img3.png" alt = ""/>
                        <p> 창의성</p>
                        <span>
                            새로운 문제 해결 방법을 찾고, 변화를 맞이합니다. 
                            문제를 다양한 시선으로 파악하고 해결해 갑니다.
                        </span>
                    </div>
                </XscrollPage>
            </section>
            <section className="recruit-second">
                <header>
                    <span>CAREERS</span>
                    <p>
                        성장을 위한 가장 확실한 방법은 뛰어난 사람들 사이에 있는 것입니다.<br/>
                        (주)오앤에서 여러분의 CAREER를 꽃피우세요.
                    </p>
                </header>
                <XscrollPage id = "careers" btnNum = "3">
                    <div className="careers-box">
                        <div/>
                        <img src = "./Recruit/img4.png" alt = ""/>
                        <p> TECH</p>
                        <span>
                            각 분야 최고의 개발자들이 모여있습니다. 
                            함께 협업을 하여 프로젝트와 서비스를 위한 
                            기술을 구현합니다. 이 과정을 통해 다가오는
                            미래를 대비할 수 있으며, 기술로서 사회에
                            이바지하는 모습을 볼 수 있습니다.
                        </span>
                    </div>
                    <div className="careers-box">
                        <div/>
                        <img src = "./Recruit/img5.png" alt = ""/>
                        <p> DESIGN</p>
                        <span>
                            시야가 넓고 깊게 보는 디자이너들이 여러분을 
                            기다리고 있습니다. 서비스와 브랜드를 
                            사용자에게 전달될 수 있도록 고민하고, 
                            독창적이고 창의적인 디자인을 작업합니다.
                        </span>
                    </div>
                    <div className="careers-box">
                        <div/>
                        <img src = "./Recruit/img6.png" alt = ""/>
                        <p> SERVICE & BUSINESS</p>
                        <span>
                            고객 가치를 만들어가며 도전을 함께할 인재를
                            찾습니다. 데이터 분석과 활용을 하며, 복잡한
                            문제를  체계적으로 정리하고, 종합합니다.
                            서비스의 모든 프로세스를 관리하며, 누구나
                            공감할 수 있는 결과를 만들어냅니다.
                        </span>
                    </div>
                </XscrollPage>
            </section>
            <section className="recruit-thrid">
                <header>
                    <span>WELFARE BENEFITS</span>
                    <p>
                        (주)오앤에서 함께 성장할 수 있는 문화를 만들어 갑니다.
                    </p>
                </header>
                <XscrollPage id="welfare-box" btnNum = "5">
                    <div className="welfare">
                        <div>
                            <img src="./Recruit/auto_awesome.png" alt="" />
                        </div>
                        <span>
                            업무 관련<br/>
                            컨퍼런스/교육 지원
                        </span>
                    </div>
                    <div className="welfare">
                        <div>
                            <img src="./Recruit/app_registration.png" alt="" />
                        </div>
                        <span>
                            기술 블로그<br/>
                            작성 지원
                        </span>
                    </div>
                    <div className="welfare">
                        <div>
                            <img src="./Recruit/book.png" alt="" />
                        </div>
                        <span>
                            도서<br/>
                            구매 지원
                        </span>
                    </div>
                    <div className="welfare">
                        <div>
                            <img src="./Recruit/flight.png" alt="" />
                        </div>
                        <span>
                            장기 근속시<br/>
                            해외여행 지원
                        </span>
                    </div>
                    <div className="welfare">
                        <div>
                            <img src="./Recruit/computer.png" alt="" />
                        </div>
                        <span>
                            직무 수행에 필요한<br/>
                            최신 장비/ 소프트웨어 제공
                        </span>
                    </div>
                </XscrollPage>
            </section>
            <section className='recruit-fourth'>

                <header>
                    <span>JOB POSTINGS</span>
                    <p>저희들은 여러분과 함께 가기를 기다리고 있습니다.</p>
                </header>
                <div>
                    <ul className='job-postings-list'>
                        <li>
                            <details>
                                <summary>Back End Developer</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                        - Java/Spring Boot<br/>
                                        - Spring MVC, Spring Batch<br/>
                                        - JPA, MySQL
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                        - 자사 신규서비스 개발<br/>
                                        - 사용자 서비스 및 백오피스 개발
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                        - RESTful API 기반 설계 및 구현 가능하신 분<br/>
                                        - RDBMS 기반의 모델링 및 개발 경험이 있으신 분<br/>
                                        - OOP 기반 설계 및 구현 경험이 있으신 분
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - AWS 클라우드의 여러 기술을 사용가능하신 분<br/>
                                    - Node.js, Docker를 활용하실 수 있는 분<br/>
                                    - 원활한 커뮤니케이션 능력을 보유하신 분<br/>
                                    - 주도적이고 탐구정신이 있는 개발자
                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Front End Developer</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                    - React, Svelte <br/>
                                    - ES6, TypeScript, CommonJS
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                    - 자사 프론트 웹페이지 개발<br/>
                                    - 사용자 서비스 및 백오피스 UI/UX 개발
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                    - 웹 표준규격에 대한 이해가 있으신 분<br/>
                                    - 웹표준, 웹접근성, Cross Browsing, ECMAScript에 대한 이해가 있으신 분<br/>
                                    - RESTFul API를 사용하여 클라이언트 개발 경험이 있으신 분
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - Node.js를 활용하실 수 있는 분<br/>
                                    - Git 형상관리 경험이 있으신 분<br/>
                                    - 모바일 앱 내 웹뷰 개발 경험을 가지신 분<br/>
                                    - 원활한 커뮤니케이션 능력을 보유하신 분
                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>A.I Developer</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                    - Pytorch, Tensorflow 
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                    - 영상 분석/처리 알고리즘 개발<br/>
                                    - 영상 처리에 활용되는 딥러닝 모델을 도입, 검증 <br/>
                                    - 분류, 예측, 이상징후 탐지 등 다양한 영역의 AI 모델 개발<br/>
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                    - 최신 ML알고리즘과 딥러닝에 대한 학습과 구현<br/>
                                    - 대용량의 데이터를 수집, 정제, 분석할 수 있는 분<br/>
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - DCNN, GAN 등 영상처리 딥러닝 알고리즘 개발 경험을 가지신 분
                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Unity 3D Developer</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                    - Unity Engine, Unity 3D<br/>
                                    - Shader, Shader Graph
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                    - 인공지능 서비스 제작<br/>
                                    - 렌더링 최적화 연구 및 구현
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                    - Unity Engine, C#관련 개발 경험이 있으신 분<br/>
                                    -  Visual Effect Graph 활용하실 수 있는 분
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - TCP, UDP 등 응용 네트워크 개발에 대한 이해가 높으신 분<br/>
                                    - 영어로 커뮤니케이션이 가능하신 분 <br/>
                                    - 원활한 커뮤니케이션 능력을 보유하신 분
                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Publisher</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                    - HTML, SCSS,SASS, Javascript(ES6)<br/>
                                    - jQuery, git<br/>
                                    - interactive animation<br/>
                                    - 반응형, 적응형 제작
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                    - 자사 브랜드 서비스 개편 및 유지보수<br/>
                                    - 신규 프로모션 페이지 제작
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                    - W3C 표준에 대한 이해가 있으신 분<br/>
                                    - 대용량의 데이터를 수집, 정제, 분석할 수 있는 분
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - Photoshop, Zeplin 사용가능 하신 분<br/>
                                    - 긍정적인 사고와 유연성이 있으신 분
                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>UI/UX Designer</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                    - Adobe XD, Photoshop <br/>
                                    - Adobe After Effects, Adobe Premiere Pro<br/>
                                    - Zeplin
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                    - Adobe After Effects, Adobe Premiere Pro<br/>
                                    - UI/UX visual design<br/>
                                    - Digital device(web, mobile, tablet)에 대한 서비스 선행디자인
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                    - UI/UX에 대한 통찰력과 디자인 컨셉 도출하시는 분<br/>
                                    - 중, 대형 프로젝트 디자인 경험이 있으신 분<br/>
                                    - 멀티 디바이스를 충족하는 완성도 높은 디자인을 제작하실 수 있는 분 
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                    - 차별화된 시각과 명확한 디렉션의 리딩 경험이 있으신 분<br/>
                                    - 최신 트렌드와 인터랙션에 이해와 관심이 있는 분<br/>
                                    - 책임감을 바탕으로 팀워크를 중요시 하는 분

                                    </p>
                                </div>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>PO</summary>
                                <div>
                                    <span>기술</span>
                                    <p>
                                        - Figma, Sketch
                                        - PPT, Excel, Word
                                    </p>
                                    <span>주요 업무</span>
                                    <p>
                                        - 브랜드의 공통 영역 개선, 신규 서비스 리딩<br/>
                                        - 데이터 기반의 가설을 바탕으로 프로젝트 리딩<br/>
                                        - 서비스의 전략, 로드맵, 지표 설정 및 우선순위 관리
                                    </p>
                                    <span>자격요건</span>
                                    <p>
                                        - B2C, B2B 서비스 기획자/PM/PO 경험이 있으신 분<br/>
                                        - 정량적, 정성적 데이터 기반하여 주도적으로 서비스의 문제를 파악하고 개선하신 분
                                    </p>
                                    <span>우대사항</span>
                                    <p>
                                        - 데이터 추출과 분석이 가능하신 분<br/>
                                        - Agile 또는 Cross function 조직을 경험하신 분
                                    </p>
                                </div>
                            </details>
                        </li>
                        
                    </ul>
                </div>
            </section>
            <button type="button" className='top-btn'><img src = "./Home/TOP-BTN.png" alt=''/></button>
            <Footer/>
        </div>
        )
    }
}
export default Recruit