import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import './App.css';
import Home from './components/Home';
import About from './components/About';
import ServiceAI from './components/ServiceAI';
import IotM2M from './components/IotM2M'
import Recruit from './components/Recruit'


function App() {

	return (
	<Router>
		<Routes>
			<Route path = "/" element ={<Home/>}/>
			<Route path = "/About" element ={<About/>}/>
			<Route path = "/ServiceAI" element ={<ServiceAI/>}/>
			<Route path = "/IotM2M" element ={<IotM2M/>}/>
			<Route path = "/Recruit" element ={<Recruit/>}/>
		</Routes>
	</Router>
	);
}

export default App;
