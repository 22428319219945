import React from 'react'
import './IotM2M.css'
import 'animate.css';
import Menu from '../Home/menu.js'
import Footer from '../footer.js'
import XscrollPage from '../XscrollPage';

class IotM2M extends React.Component {
    constructor(props) {
    super(props);
    
    this.scrollFunc = this.scrollFunc.bind(this);
	this.topBtnFunc = this.topBtnFunc.bind(this);
    }

    /* 스크롤시 top button 보이기 */
	scrollFunc = () => {
		const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container')
		var winheight = window.innerHeight;
		if (wrapContainer.scrollTop >= winheight) {
			topBtn.style.display = "block";
		} else {
			topBtn.style.display = "none";
		}
	}

	/* top button 클릭시 화면 상단이동 */
	topBtnFunc = () => {
		const wrapContainer = document.querySelector('.wrap-container')
		wrapContainer.scrollTop = 0;
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
	}

    componentDidMount(){
        const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container');
		wrapContainer.addEventListener('scroll', this.scrollFunc)
		topBtn.addEventListener('click',this.topBtnFunc);
    }
    componentWillUnmount(){
        const topBtn = document.querySelector('.top-btn');
		const wrapContainer = document.querySelector('.wrap-container');
		wrapContainer.removeEventListener('scroll', this.scrollFunc)
		topBtn.removeEventListener('click',this.topBtnFunc);
    }

    render() {
        return (<div className="iotm2m menu-color-cg wrap-container">
            <Menu/>
            <section>
                <header className='animate__animated animate__fadeInUp'>
                    <span>
                        SERVICE - IOT
                    </span>
                    <p>
                        IoT Tech Based Service Solution
                    </p>
                </header>
                <div className="iot-line animate__animated animate__fadeInUp animate__delay-1s">
                    <ul>
                        <li>
                            <div>

                            </div>
                            <p>
                                혁신
                            </p>
                        </li>
                        <li>
                            <div>

                            </div>
                            <p>
                                컨셉과 디자인
                            </p>
                        </li>
                        <li>
                            <div>

                            </div>
                            <p>
                                프로토 타입 개발
                            </p>
                        </li>
                        <li>
                            <div>

                            </div>
                            <p>
                                산업화
                            </p>
                        </li>
                        <li>
                            <div>

                            </div>
                            <p>
                                소규모 생산
                            </p>
                        </li>
                    </ul>
                </div>
                <XscrollPage id = "iot-contents" btnNum = "5">
                    <div className="iot-box animate__animated animate__fadeInUp animate__delay-2s">
                        <img src="./IotM2M/icon1.svg" alt=""/>
                        <p>혁신적 기술 도입</p>
                        <span>
                            혁신은 시장 성공의 핵심 동인입니다. 
                            초기 아이디어/거친 요구 사항을 기반으로 
                            제품 비전과 정의를 공동으로 개선할 수 있습니다. 
                            하드웨어 및 소프트웨어 전문가의 노하우에 
                            의존하여 성공적인 솔루션을 구상하십시오. 
                            마케팅 및 영업 팀의 참여도 옵션입니다. 
                            10년 이상의 경험을 바탕으로 귀사의 비전을 
                            성공적인 시장 진출을 위한 제품으로 만들어 
                            드립니다.
                        </span>
                    </div>
                    <div className="iot-box animate__animated animate__fadeInUp animate__delay-2s">
                        <img src="./IotM2M/icon2.svg" alt=""/>
                        <p>컨셉과 아이디어</p>
                        <span>
                            제품 개발의 다음 단계는 제품의 개념을 설정하는 것입니다. 
                            귀하와 협력하여 당사 팀은 제품의 모든 측면
                            (기능, 소프트웨어, 하드웨어, 역학)을 정의합니다. 
                            여기에는 종종 요구 사항의 개선도 포함되어 전체 제품 사양이 생성됩니다.
                            팀은 주요 제품 구성 요소(예: 소프트웨어 플랫폼, 
                            하드웨어 플랫폼, 리소스, 하우징 자재)를 선택합니다. 
                            선택한 구성 요소와 구매 여부 결정에 따라 개발 프로세스가 계획됩니다. 
                            이 단계의 또 다른 중요한 결과는 비즈니스 사례를 
                            검증하는 데 필요한 입력을 얻는 것입니다.
                            즉시 해결할 수 없는 미해결 질문과 위험이 있을 수 있습니다. 
                            이 단계에서 실험 및 위험 제거가 수행됩니다. 
                            필요한 경우 추가 도메인 전문가를 고용하여 
                            우리의 전문 지식 이외의 미해결 질문을 해결합니다.
                        </span>
                    </div>
                    <div className="iot-box animate__animated animate__fadeInUp animate__delay-2s">
                        <img src="./IotM2M/icon3.svg" alt=""/>
                        <p>프로토타입 개발</p>
                        <span>
                            프로토타입 개발은 실행 계획에 따라 진행됩니다. 
                            필수 프로필(소프트웨어 엔지니어, 하드웨어 엔지니어, 
                            기계 엔지니어, QA 엔지니어, 프로젝트/제품 관리자)로 
                            구성된 전담 팀이 사양을 충족하는 고품질 제품에 
                            도달하기 위해 능동적으로 노력합니다. 
                            그 결과 기능 테스트, 가정 및 위험 검증의 대상이 
                            될 수 있는 거의 완성된 제품입니다. 
                            우리는 추가 테스트에 필요한 프로토타입 개발을 조직합니다.
                            이 단계의 마지막 단계는 제작된 시제품에 대한 
                            철저한 테스트, 사전 인증 및 현장 테스트입니다. 
                            그 산출물은 산업화를 위한 투입물이다.
                        </span>
                    </div>
                    <div className="iot-box animate__animated animate__fadeInUp animate__delay-2s">
                        <img src="./IotM2M/icon4.svg" alt=""/>
                        <p>상용화</p>
                        <span>
                            산업화의 목표는 제조를 위해 제품을 개선하고 준비하는 것입니다. 
                            제품은 비용, 성능 면에서 최적화되어 있으며 때로는 
                            선택한 EMS 기능에 맞게 조정됩니다. 
                            마지막으로 제품 디자인이 동결되어 제조를 위해 EMS로 인계됩니다. 
                            EMS는 제조 프로세스 설정에 있어 전폭적인 지원을 받습니다.
                            첫 번째 제조 샘플은 인증 연구소로 보내집니다. 
                            우리 주변의 신뢰할 수 있는 인증 연구소와의 오랜 협력을 
                            활용하여 전문가들이 필요한 소프트웨어의 개발자로서 
                            프로세스에 적극적으로 참여하고 인증을 설정합니다.
                            이 단계에서 우리는 또한 포장, 포장의 내용, 사용 설명서를 지정합니다. 
                            우리의 디자인 팀은 시각 자료를 다루는 데 도움을 줄 수 있습니다.
                        </span>
                    </div>
                    <div className="iot-box animate__animated animate__fadeInUp animate__delay-2s">
                        <div/>
                        <img src="./IotM2M/icon5.svg" alt=""/>
                        <p>소규모 생산</p>
                        <span>
                            우리는 당신과 EMS 사이의 다리 역할을 할 수 있습니다. 
                            소규모 시리즈(연간 10k)로 생산하는 경우 유연성, 
                            우수한 품질 및 우수한 리드 타임을 제공하는 현지 EMS를 이용할 수 있습니다. 
                            이렇게 하면 여러 공급업체와 거래할 필요가 없고 위험이 줄어듭니다. 
                            필요한 경우 제품의 더 작은 배치를 생산하거나 
                            EMS의 저장 용량을 사용할 가능성이 있습니다.
                        </span>
                    </div>
                </XscrollPage>
            </section>
            <button type="button" className='top-btn'><img src = "./Home/TOP-BTN.png" alt=''/></button>
            <Footer/>
        </div>
        )
    }
}
export default IotM2M