import React from 'react'
import './ServiceAI.css'
import 'animate.css';
import Menu from '../Home/menu.js'
import Footer from '../footer.js'
import XscrollPage from '../XscrollPage';
import OpacitySwipe from '../OpacitySwipe';
class ServiceAI extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vdSrc: "./ServiceAi/AI-BG-1920.mp4",
			sectionAnimate : [false, false, false]
		};
		this.vdChange = this.vdChange.bind(this);
		//this.xScroll = this.xScroll.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.scrollFunc = this.scrollFunc.bind(this);
		this.topBtnFunc = this.topBtnFunc.bind(this);

		if(window.outerWidth<1025){
			this.state.vdSrc = "./ServiceAi/AI-BG-1024.mp4";
		}
		else if(window.outerWidth<600){
			this.state.vdSrc = "./ServiceAi/AI-BG-390.mp4";
		}
		
	}
	/* 스크롤시 top button 보이기 */
	scrollFunc = () => {
		const scrollTarget = document.querySelector('.wrap-container')
		const currentTop = scrollTarget.scrollTop > window.scrollY?scrollTarget.scrollTop:window.scrollY
		const topBtn = document.querySelector('.top-btn');
		var winheight = window.innerHeight;
		if (currentTop >= winheight) {
			topBtn.style.display = "block";
		} else {
			topBtn.style.display = "none";
		}
	}

	/* top button 클릭시 화면 상단이동 */
	topBtnFunc = () => {
		const scrollTarget = document.querySelector('.wrap-container')
		scrollTarget.scrollTop = 0
		window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
		
	}

	// 스크롤이 위치 감지 애니메이션 이벤트
	onScroll = event => {
		this.scrollFunc()
		const scrollTarget = document.querySelector('.wrap-container')
		if((window.scrollY/scrollTarget.scrollHeight)>0.5){
			if(!this.state.sectionAnimate[2]){
				const dyvityImg = document.querySelector('.opacity-swiper')
				dyvityImg.classList.add('animate__animated')
				dyvityImg.classList.add('animate__fadeInUp')
			}
			this.setState({
				sectionAnimate : [true, true, true]
			});
		}
		else if((window.scrollY/scrollTarget.scrollHeight)>0.3){
				if(!this.state.sectionAnimate[1]){
					document.querySelector('.serviceai-page-second-text').classList.add('animate__animated')
					document.querySelector('.serviceai-page-second-text').classList.add('animate__fadeInUp')
			}
			this.setState({
				sectionAnimate : [true, true, false]
			});
		}
		else if((window.scrollY/scrollTarget.scrollHeight)>0.05){
				if(!this.state.sectionAnimate[0]){
				document.querySelector('#serviceai .swiper-slide').classList.add('animate__animated')
				document.querySelector('#serviceai .swiper-slide').classList.add('animate__fadeInUp')
				
			}
			this.setState({
				sectionAnimate : [true, false, false]
			});
		}
	}
	
	vdChange = event =>{
		const video = document.getElementById('serAiVd')
		if(window.outerWidth> 1024 && this.state.vdSrc !== "./ServiceAi/AI AI-BG-1920.mp4"){
			this.setState({
				vdSrc: "./ServiceAi/AI-BG-1920.mp4"
			});
			video.load()
		}
		else if(window.outerWidth> 600 && window.outerWidth < 1024  && this.state.vdSrc !== "./ServiceAi/AI-BG-1024"){
			this.setState({
				vdSrc: "./ServiceAi/AI-BG-1024"
			});
			video.load()
		}
		else if(window.outerWidth < 600 && this.state.vdSrc !== "./ServiceAi/AI-BG-390.mp4"){
			this.setState({
				vdSrc: "./ServiceAi/AI-BG-390.mp4"
			});
			video.load()
		}
	}
	componentDidMount(){
		window.addEventListener('resize', this.vdChange)
		//const scrollTarget = document.querySelector('#root')
		const scrollTarget = document.querySelector('.wrap-container')
		window.addEventListener('scroll', this.onScroll);
		scrollTarget.addEventListener('scroll', this.onScroll)
		const topBtn = document.querySelector('.top-btn');
		topBtn.addEventListener('click',this.topBtnFunc)
    }
	componentWillUnmount(){
		window.removeEventListener('resize', this.vdChange)
		const scrollTarget = document.querySelector('.wrap-container')
		window.removeEventListener('scroll', this.onScroll);
		const topBtn = document.querySelector('.top-btn');
		scrollTarget.removeEventListener('scroll',this.onScroll)
		topBtn.removeEventListener('click',this.topBtnFunc)
    }
	render() {
	return (
	<div className="wrap-container-serviceai menu-color-cg wrap-container-scrollpage wrap-container">
		<Menu/>

		<section className="serviceai-page-first">
		<div className = "video-bg">
			<video id= "serAiVd" muted autoPlay loop>
				<source src={this.state.vdSrc} type="video/mp4"/>
			</video>
		</div>
		<div className="serviceai-page-first-contents">
			<div className="serviceai-page-first-text">
						<p className='animate__animated animate__fadeInUp'>SERVICE - A.I.</p>
						<span className='animate__animated animate__fadeInUp'>
							A.I. Vision Based Service Solution
						</span>
						<p className='animate__animated animate__fadeInUp animate__delay-1s'>
							누구도 상상하지 못했던 인공 지능 기술 실현 시대부터 앞으로도,
							모든 산업에서 크고 작은 문제들이나, 도움을 필요로하는 요소들를 해결하고 있습니다.
						</p>
						<span className='animate__animated animate__fadeInUp animate__delay-1s'>
							자체적으로 개발한 인공 지능 분석 엔진과 이를 토대로 한 영상 분석 서비스들을 제공하고 있으며, 
							전 세계적으로 훌륭한 기술자들이 모여 진행 중인 오픈 소스 대열에 합류하여 필요로 하는 
							기술을 끊임없이 연구하고 있습니다. (주)오앤의 사명은 저희의 기술을 필요로 하는 보편적 사이트에 
							유연하게 엑세스 할 수 있도록 만들어 해당 기술을 유용하게 쓰일 수 있도록 하는 것입니다. 
							문제들을 해결하면서 흥미롭고 새로운 방식으로 이를 수행하도록 돕고 있습니다.
						</span>
			</div>
			<XscrollPage id = "serviceai" btnNum = "3" mobBtnNum = "6">
				
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-sports.png" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-sports.png" alt = "" className='dyvity-logo'/>
					<p> 스포츠 영상 분석</p>
					<span className='title-sub'>
						실시간 경기 영상 분석을 통한 선수들의 
						기술 구현, 경기 이력 기록, 빅데이터 연계 분석 등
					</span>
					<span>
						휴먼 객체에 대한 영상 분석을 통해 선수들의 
						경기 이력 기록부터 축적된 경기 결과 데이터, 
						주요 기술 분석 데이터를 축적하고 서비스
						합니다.
					</span>
				</div>
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-retail.png" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-retail.png" alt = "" className='dyvity-logo'/>
					<p> 매장 영상 분석</p>
					<span className='title-sub'>
						매장 관리를 위한 고객층 분석, 고용인들의 
						업무 분석, 위험 요소 감지 등의 영상 분석 서비스
					</span>
					<span>
						매장 관리를 실시간으로 하기엔 어렵습니다. 
						실시간 영상 분석 기술을 도입하여 매장 내 
						유동 인구, 연령, 성별, 인기 메뉴 추이, 
						재구매 여부, 이동 정보 등의 분석으로 통해 
						매출을 극대화합니다.
					</span>
				</div>
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-space.png" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-space.png" alt = "" className='dyvity-logo'/>
					<p> 토목건설 공사 현장 영상 분석</p>
					<span className='title-sub'>
						안전을 위한 현장 영상 실시간 분석, 
						신속 알림 등의 서비스
					</span>
					<span>
						토목 공사 현장은 안전이 최우선 입니다. 
						어려 위험 요소로부터 안전하게, 모든 
						작업자가 안전하게 최고의 실력을 발휘할 
						수 있도록 합니다.
					</span>
				</div>
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-eco.svg" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-eco.png" alt = "" className='dyvity-logo'/>
					<p>친환경 물절약 시스템<br/>원격 데이터 분석, 기기제어</p>
					<img src = "./ServiceAi/wastem_logo.png" className='wastem-logo' alt = ""/>
					<span className='title-sub'>
						물 사용량을 측정, 분석하여 재사용 가능토록 
						이물질을 제거하고 살균 소독하여 재사용 할 수 있도록
						IoT 기기를 제어하는 시스템
					</span>
					<span>
						한번 쓰고 버리는 물, 재사용할 수 있도록 이물질을 
						제거하고 살균 소독하여 한 번 더 쓸 수 있도록 하여 
						절수가 가능한 친환경 AI 절수 시스템 입니다.
					</span>
				</div>
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-factory.png" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-factory.png" alt = "" className='dyvity-logo'/>
					<p>공장내 CCTV 영상 분석</p>
					<span className='title-sub'>
						다양한 공장 내 CCTV 영상을 분석, 위험요소부터 
						안전 수칙에 따른 보호장구 착용 유무 분석까지 
						실시간으로 한 번에!
					</span>
					<span>
						다양한 공장 내 CCTV 영상을 실시간으로 분석하여 
						위험요소 및 안전장구, 위생모, 청결도 유지, 이물질 
						투척 등의 행위를 분석하여 모니터링합니다.
					</span>
				</div>
				<div className="serviceai-box">
					<div/>
					<img src = "./ServiceAi/dyvity-care.png" alt = ""/>
					<img src = "./ServiceAi/dyvity-logo-care.png" alt = "" className='dyvity-logo'/>
					<p>휴먼객체 모니터링 솔루션</p>
					<span className='title-sub'>
						실시간 영상 내 휴먼 객체를 인식하여 위험요소 감지, 
						영유아의 두상 골격의 변화 분석과 이를 통한 다양한 
						서비스 제공
					</span>
					<span>
						영유아부터 실버세대까지, 다양한 연령층의 
						휴먼 객체 실시간 영상 분석 기술을 기반으로 영유아의 
						두상 교정부터 실버세대의 위험요소까지 실시간으로 
						모니터링합니다.
					</span>
				</div>
				
			</XscrollPage>
		</div>
		</section>
		<section className="serviceai-page-second">
			<div className='serviceai-page-second-text'>
				<div><img src = "./ServiceAi/dyvity-logo.png" alt = ""/></div>
				<span>AI Vision Solution. Dynamic Visual Acuity</span>
			</div>
			<OpacitySwipe id="dyvity" paginations="ECO,CARE,FACTORY, SPORTS,RETAIL, SPACE, TRANSIT" btnNum = "7">
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_ECO.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-eco.png" alt = ""/>					
						<span>실시간 절수 모니터링 및 관련 서비스 시스템 제공 솔루션</span>
					</div>
				</div>
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_CARE.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-care.png" alt = ""/>					
						<span>휴먼 객체를 위한 관절 변화와 모션감지에 특화된 공간 영상분석 및 서비스 시스템</span>
					</div>
				</div>
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_FACTORY.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-factory.png" alt = ""/>					
						<span>공장 자동화를 위한 시스템에 특화된 공간 영상분석 및 서비스 시스템</span>
					</div>
				</div>
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_SPORTS.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-sports.png" alt = ""/>					
						<span>스포츠 경기 영상 및 운영정보 데이터화, 선수 이력 관리 가능, 팀 관리 솔루션</span>
					</div>
				</div>		
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_RETAIL.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-retail.png" alt = ""/>					
						<span>매장관리를 위한 유먼객체 탐지 및 매장관리에 특화된 공간
							영상분석 및 서비스 시스템</span>
					</div>
				</div>
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_SPACE.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-space.png" alt = ""/>					
						<span>공간에 대한 실시간 영상을 분석, 객체 탐지부터 모션 탐지까지 한 번에 하는 공간 영상분석 및 서비스 시스템</span>
					</div>
				</div>
				<div className="slide">
					<img src = "./ServiceAi/DYVITY_TRANSIT.png" alt = ""/>
					<div  className='logo-description'>
						<img src = "./ServiceAi/dyvity-logo-space.png" alt = ""/>					
						<span>운송 장비 및 운전자의 안전을 위해 실시간 영상 분석 및 관리에 특화된 서비스 시스템</span>
					</div>
				</div>

			</OpacitySwipe>
		</section >
		<button type="button" className='top-btn'><img src = "./Home/TOP-BTN.png" alt=''/></button>
		<Footer/>
	</div>
	)
}}

export default ServiceAI