import React  from 'react';

function XscrollPage (props) {

	/* 이전 클릭 이벤트 */
	function prevBtn(){
		const xscroll = document.getElementById(`${props.id}`);
		var xwidth = xscroll.querySelector('.scroll-home-second-xscroll').offsetWidth;
		const xposition = xscroll.querySelector('.scroll-home-second-xscroll').scrollLeft;
		xscroll.querySelector('.scroll-home-second-xscroll').scrollTo({
			top: 0,
			left: xposition - xwidth,
			behavior: 'smooth'
		})
        
	}
	/* 다음 클릭 이벤트 */
	function  nextBtn(){
		const xscroll = document.getElementById(`${props.id}`);
		var xwidth = xscroll.querySelector('.scroll-home-second-xscroll').offsetWidth;
		const xposition = xscroll.querySelector('.scroll-home-second-xscroll').scrollLeft
		xscroll.querySelector('.scroll-home-second-xscroll').scrollTo({
			top: 0,
			left: xposition + xwidth,
			behavior: 'smooth'
		})
        
	}

    /* swiper버튼 클릭 이벤트 */
	function swiperBtn(num){
		const xscroll = document.getElementById(`${props.id}`);
		var xwidth = xscroll.querySelector('.scroll-home-second-xscroll').offsetWidth;
		xscroll.querySelector('.scroll-home-second-xscroll').scrollTo({
			top: 0,
			left: num*xwidth,
			behavior: 'smooth'
		})
        
	}


	/* 좌 스크롤시 swiper버튼 변동 이벤트 */
	function xscrollChange(){
		const xscroll = document.getElementById(`${props.id}`);
		const xposition = xscroll.querySelector('.scroll-home-second-xscroll').scrollLeft
		var xwidth = xscroll.querySelector('.scroll-home-second-xscroll').offsetWidth;
		//var maxwidth = xscroll.querySelector('.scroll-home-second-xscroll').scrollWidth;

		xscroll.querySelector(".selected").classList.remove("selected")
		xscroll.querySelector(`#${props.id}${parseInt(xposition/(xwidth*0.9))}`).classList.add("selected")
	}
	
	const styles = {width:props.width};
	const btnList = () =>{
		const btn = [<button id = {`${props.id}0`} key="0" className = "selected" onClick={()=>{swiperBtn(0)}}></button>]
		var btnNum
		if(window.outerWidth<701 && props.mobBtnNum){
			window.addEventListener("resize",()=>{
			  if(window.outerWidth<701)
				btnNum = props.mobBtnNum
			})
			btnNum = props.mobBtnNum
		  }
		  else{
			if(typeof props.btnNum === "undefined"){
			  btnNum = 3
			}
			else{
			  btnNum = props.btnNum
			}
		  }
		
		for(let i = 1; i <btnNum; i++) {
			btn.push(<button id = {`${props.id}${i}`} key={`${i}`} className = "" onClick={()=>{swiperBtn(`${i}`)}}></button>)
		}
		return(btn)
	}

		return (
				<div id = {`${props.id}`} style={styles}>
					<button className ="prev-btn move-btn" onClick={prevBtn}>
						<img src = "./ServiceAi/prevBtn.png" alt = ""/>	
					</button>
					<div className="swiper-slide" >
						<div onScroll={xscrollChange} className="scroll-home-second-xscroll">
							{props.children}
						</div>
						<div className="swiper-btn-container">
							{/* <button id = {`${props.id}0`} className = "selected" onClick={()=>{swiperBtn(0)}}></button>
							<button id = {`${props.id}1`} className = "" onClick={()=>{swiperBtn(1)}}></button>
							<button id = {`${props.id}2`} className = "" onClick={()=>{swiperBtn(2)}}></button> */}
							{btnList()}
						</div>
					</div>
					<button className ="next-btn move-btn" onClick={nextBtn}>
						<img src = "./ServiceAi/nextBtn.png" alt = ""/>
					</button>
				</div>
		);
	
}


export default XscrollPage