import React from 'react';
import {Link} from 'react-router-dom';
class footer extends React.Component {
	constructor(props) {
	super(props);
	this.dropDownShow = this.dropDownShow.bind(this);
}
/* footer 패밀리사이트 드롭다운메뉴 */
dropDownShow = () => {
	const dropBtnTxt = document.querySelector('.dropbtn span')
	const dropBtnIcon = document.querySelector('.dropbtn img')
	const dropListBox = document.querySelector('.dropdown-content')
	const dropList = document.querySelectorAll('.dropdown-content li > *')

	dropListBox.classList.toggle('show')
	dropBtnIcon.classList.toggle('rotate')
	//console.log(dropListBox)

	dropList.forEach(function(t,i){
		t.addEventListener('click',function(){
			dropBtnTxt.innerText = t.innerText
		})
	})

	document.addEventListener("click", function (e) {
		const obj = e.target
		if(obj.closest('.dropdown') === null){
			dropListBox.classList.remove('show')
			dropBtnIcon.classList.remove('rotate')
		}
	})
}

componentDidMount(){
	const dropDown = document.querySelector('.dropdown')
	dropDown.addEventListener('click',this.dropDownShow)
}
componentWillUnmount(){
	const dropDown = document.querySelector('.dropdown')
	dropDown.removeEventListener('click',this.menuDropDown)
	
}

render() {

	return (
			<footer>
				<div className="footer-box">
					<div className="logo-box">
						<img src="./logo.svg" alt ="5n-logo"/>
					</div>
					<div>
						<ul>
							<li>
								<strong>We believe in Simple, Creative & Flexible IT Technology Standards.</strong>
							</li>
							<li>
								<p><strong>Headquarters</strong></p>
								<span>6F 604, 26, Magokjungang 1-ro, Gangseo-gu, Seoul, Republic of Korea<br/>
								서울특별시 강서구 마곡중앙1로 26 마곡엠리체 6층 604호</span>
							</li>
						</ul>
						<ul>
							<li>
								<strong>We give technology the power of sight.</strong>
							</li>
							<li>
								<p><strong>Live R&D Space</strong></p>
								<span>
									521 Live Bdg2, 1615-11, Seoho-dong, Seogwipo-si, Jeju-do, Republic of Korea<br/>
									제주특별자치도 서귀포시 서호동 1615-11 테트라필드 제주라이브 빌딩 5층 521호
								</span>
							</li>
						</ul>
						<ul className="contact-box">
							<li>
								<ul className='contact-list'>
									<li>
										<strong>Phone</strong>&nbsp;&nbsp;&nbsp;(82) 02 2661 5557
									</li>
									<li>
										<strong>Fax</strong>&nbsp;&nbsp;&nbsp;(82) 0303 3440 7612
									</li>
									<li>
										<strong>Email</strong>&nbsp;&nbsp;&nbsp;proposal@5n.co.kr
									</li>
								</ul>
							</li>
							<li className='family-site'>
								<div className='dropdown'>
									<button className='dropbtn'>
										<span>Family Site</span>
										<img src= "./ico-arrow-down.png" alt=""/>
									</button>
									<ul className="dropdown-content">
										<li><Link to="https://dyvity.com/">다이비티</Link></li>
										<li><Link to="https://cocle.kr/">코딩클라우드 학원</Link></li>
										{/* <li><Link to="">오앤글로벌 마곡</Link></li>
										<li><Link to="">오앤글로벌 분당</Link></li>
										<li><Link to="">㈜노플라스틱</Link></li>
										<li><Link to="">㈜코리아키즈</Link></li>
										<li><Link to="">㈜오앤 홀딩스</Link></li> */}
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="footer-bottom">
					Copyrights &copy; 2014~2023 All Rights Reserved by 5N Co.,Ltd.
				</div>
			</footer>
	);
}
}
export default footer