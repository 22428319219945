import React from 'react';
import {Link} from 'react-router-dom';
class Menu extends React.Component {
		constructor(props) {
		super(props);
		this.state = {
			menuToggle:0

		};
		this.menuChange = this.menuChange.bind(this);
		this.gnbChg = this.gnbChg.bind(this);
	}


	/*상단 메뉴 버튼*/
	menuChange = () =>{
		const menu = document.querySelector(".menu-page")
		if(this.state.menuToggle){
			this.setState({menuToggle:0})
			menu.classList.remove("visible")
			setTimeout(function() {
				document.querySelector(".gnb-menuX").classList.remove("rtate");
			}, 300);
		}
		else{
			this.setState({menuToggle:1})
			menu.classList.add("visible")
			document.querySelector(".gnb-menuX").classList.add("rtate")
		}
		
	}

	/* gnb 색 바꾸기 */
	gnbChg = () =>{
		const wdw = document.querySelector(".menu-color-cg")
		const htm = document.querySelector("html")
		if(wdw.scrollTop>0 || htm.scrollTop>0){
			document.querySelector(" .gnb-wrap").style.backgroundColor="rgba(0, 0, 0, 0.7)";
			document.querySelector(" .gnb-wrap").style.borderBottom="0"
		}else{
			document.querySelector(" .gnb-wrap").style.backgroundColor="rgba(0, 0, 0, 0)";
			document.querySelector(" .gnb-wrap").style.borderBottom="1px solid rgba(255, 255, 255, 0.2)"
		}
	}
	componentDidMount(){
		window.addEventListener("scroll",this.gnbChg)
		const wdw = document.querySelector(".menu-color-cg")
		wdw.addEventListener("scroll",this.gnbChg)
	}
	componentWillUnmount(){
		const wdw = document.querySelector(".menu-color-cg")
		wdw.addEventListener("scroll",this.gnbChg)
		window.removeEventListener("scroll",this.gnbChg)
	}
	render() {
	return (
		<>
			<div className="gnb-wrap">
				<Link to="/">
				<img src="./5n-logo-white.png" alt ="5n-logo"></img>
				</Link>
				<div className="gnb-menu" onClick={this.menuChange}>
					<hr className="menubar-one"/>
					<hr className="menubar-two"/>
					<hr className="menubar-three"/>
				</div>
			</div>
			<div className="menu-page">
				<div className="gnb-wrap">
					<div className="gnb-menuX" onClick={this.menuChange}>
						×
					</div>
				</div>
				<ul>
					<li>
						<Link to="/" onClick={this.menuChange}>HOME</Link>
					</li>
					<li >
						<Link to="/About" onClick={this.menuChange}>COMPANY</Link>
						{/* <span/>
						<ul className="gnb-sub">
							<li>
								<Link to="/About">ABOUT</Link>
							</li>
							<li>
								<Link to="/About">HISTORY</Link>
							</li>
							<li>
								<Link to="/About">CI</Link>
							</li>
							<li>
								<Link to="/About">LOCATION</Link>
							</li>
							<li>
								<Link to="/About">SNGROUP</Link>
							</li>
						</ul> */}
					</li>
					<li>
						<Link to="/ServiceAI">SERVICE</Link>
						<span/>
						<ul className="gnb-sub">
							<li>
								<Link to="/ServiceAI" onClick={this.menuChange}>ARTIFICIAL INTELLIGENT</Link>
							</li>
							<li>
								<Link to="/IotM2M" onClick={this.menuChange}>IOT & M2M</Link>
							</li>
							<li>
							</li>
						</ul>
					</li>
					<li>
						<Link to="/" onClick={this.menuChange}>BRAND</Link>
						<span/>
						<ul className="gnb-sub">
							<li>
								<a href="https://dyvity.com/"> DYVITY</a>
							</li>
							<li>
								<a href="https://codly.co.kr/"> CODLY</a>
							</li>
							<li>
								<a href="https://www.cocle.kr/"> CODING CLOUD</a>
							</li>
							<li>
								<a href="https://rcv.5n.co.kr/"> LOOP</a>
							</li>
							<li>
								<a href="http://home.sportsdiary.co.kr/"> SPORTS DIARY</a>
							</li>
						</ul>
					</li>
					{/* <li>
						<Link to="/">REFERENCE</Link>
					</li> */}
					<li>
						<Link to="/Recruit" onClick={this.menuChange}>RECRUIT</Link>
					</li>
				</ul>
			</div>
		</>
	);
}}

export default Menu